<template>
    <properties-component v-bind="$props" @close="$emit('close')">

        <b-form-group>
            <b-btn variant="outline-primary" block @click="editLinks = true">
                <i class="fas fa-link mr-1"/>
                <span v-tr>Edit Links|Modifier les liens</span>
            </b-btn>
        </b-form-group>

        <sidebar-group title="Link Style|Style des liens" :expanded.sync="styleExpanded" sub-group>
            <b-form-group>
                <template #label>
                    <btn-responsive-prop v-model="component" field="buttonType" no-label/>
                </template>
                <input-button-type v-model="component.mediaProxy.buttonType"/>
            </b-form-group>

            <b-form-group>
                <template #label>
                    <btn-responsive-prop v-model="component" field="borderRadius" no-label/>
                </template>
                <input-border-radius v-model="component.mediaProxy.borderRadius"/>
            </b-form-group>

            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Display|Affichage</span>
                    <btn-responsive-prop v-model="component" field="display"/>
                </template>
                <b-select v-model="component.mediaProxy.display">
                    <option value="text" v-tr>Text only|Texte seulement</option>
                    <option value="icon" v-tr>Icon only|Icône seulement</option>
                    <option value="text-icon-top" v-tr>Text & Top Icon|Texte et icône haut</option>
                    <option value="text-icon-left" v-tr>Text & Left Icon|Texte et icône gauche</option>
                    <option value="text-icon-right" v-tr>Text & Right Icon|Texte et icône droite</option>
                </b-select>
            </b-form-group>

            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Text Size|Taille texte</span>
                    <btn-responsive-prop v-model="component" field="size"/>
                </template>
                <b-select v-model="component.mediaProxy.size">
                    <option value="sm" v-tr>Small|Petit</option>
                    <option value="md" v-tr>Normal</option>
                    <option value="lg" v-tr>Large</option>
                </b-select>
            </b-form-group>

            <b-form-group label-cols="4" v-if="component.mediaProxy.display !== `text`">
                <template #label>
                    <span v-tr>Icon Size|Taille icône</span>
                    <btn-responsive-prop v-model="component" field="iconSize"/>
                </template>
                <b-select v-model="component.mediaProxy.iconSize">
                    <option value="2xs" v-tr>Extra Small|Ultra petit</option>
                    <option value="xs" v-tr>Very Small|Très petit</option>
                    <option value="sm" v-tr>Small|Petit</option>
                    <option value="1x" v-tr>Normal</option>
                    <option value="lg" v-tr>Large</option>
                    <option value="xl" v-tr>Very Large|Très large</option>
                    <option value="2xl" v-tr>Extra Large|Ultra large</option>
                </b-select>
            </b-form-group>
        </sidebar-group>

        <sidebar-group title="Link Layout|Disposition des liens" :expanded.sync="layoutExpanded" sub-group>
            <b-form-group label-cols="5">
                <template #label>
                    <span v-tr>Layout|Disposition</span>
                    <btn-responsive-prop v-model="component" field="layout"/>
                </template>
                <b-select v-model="component.mediaProxy.layout">
                    <option value="horizontal" v-tr>Horizontal|Horizontale</option>
                    <option value="vertical" v-tr>Vertical|Verticale</option>
                </b-select>
            </b-form-group>

            <b-form-group v-if="component.mediaProxy.layout === `horizontal`">
                <btn-responsive-prop v-model="component" field="fillSize" for-checkbox/>
                <b-checkbox v-model="component.mediaProxy.fillSize" switch>
                    <span v-tr>Fill Height|Remplir la hauteur</span>
                </b-checkbox>
            </b-form-group>

            <b-form-group label-cols="5">
                <template #label>
                    <span v-tr>Width Mode|Mode largeur</span>
                    <btn-responsive-prop v-model="component" field="linkSizeMode"/>
                </template>
                <b-select v-model="component.mediaProxy.linkSizeMode">
                    <option value="auto" v-tr>Auto</option>
                    <option value="justify" v-tr>Uniform|Uniforme</option>
                    <option value="custom" v-tr>Custom|Personnalisée</option>
                </b-select>
            </b-form-group>

            <b-form-group label-cols="5" v-if="component.mediaProxy.linkSizeMode === 'custom'">
                <template #label>
                    <span v-tr>Width|Largeur</span>
                    <btn-responsive-prop v-model="component" field="linkSize"/>
                </template>
                <b-select v-model="component.mediaProxy.linkSize">
                    <option v-for="n in 100" :key="`width-${n}`" :value="`${n*5}px`">{{n*5}}px</option>
                </b-select>
            </b-form-group>

            <b-form-group label-cols="5">
                <template #label>
                    <span v-tr>Spacing|Espacement</span>
                    <btn-responsive-prop v-model="component" field="spacing"/>
                </template>
                <b-select v-model="component.mediaProxy.spacing">
                    <option value="0px" v-tr>None|Sans</option>
                    <option v-for="n in 100" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
                </b-select>
            </b-form-group>
        </sidebar-group>

        <sidebar-group title="Divider|Séparateur" :expanded.sync="separatorExpanded" sub-group>
            <b-form-group>
                <btn-responsive-prop v-model="component" field="separator" for-checkbox/>
                <b-checkbox v-model="component.mediaProxy.separator" switch>
                    <span v-tr>Border between items|Bordure entre les éléments</span>
                </b-checkbox>
            </b-form-group>

            <template v-if="component.mediaProxy.separator">
                <b-form-group label-cols="4">
                    <template #label>
                        <span v-tr>Width|Épaisseur</span>
                        <btn-responsive-prop v-model="component" field="separatorWidth"/>
                    </template>
                    <b-select v-model="component.mediaProxy.separatorWidth">
                        <option value="none" v-tr>None|Sans</option>
                        <option v-for="n in 50" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
                    </b-select>
                </b-form-group>

                <b-form-group label-cols="4">
                    <template #label>
                        <span v-tr>Color|Couleur</span>
                        <btn-responsive-prop v-model="component" field="separatorColor"/>
                    </template>
                    <input-color v-model="component.mediaProxy.separatorColor" use-css-var/>
                </b-form-group>
            </template>
        </sidebar-group>

        <b-modal v-model="editLinks" size="xl" scrollable :title="'Edit Links|Modifier les liens'|tr">
            <b-table-simple>
                <thead>
                    <tr>
                        <th></th>
                        <th v-tr>Social Media|Réseau Social</th>
                        <th v-tr>Account|Compte</th>
                        <th v-tr>Display Label|Texte à afficher</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="type in linkTypes" :key="type.value" style="line-height: 40px;">
                        <td>
                            <b-checkbox :checked="isEnabled(type)" class="mt-1" size="lg" @input="toggle(type, $event)"/>
                        </td>
                        <td style="user-select: none">
                            <i class="fa-fw mr-1" :class="[fontAwesomePrefix, type.glyph]"/>
                            {{type.label|tr}}
                        </td>
                        <td>
                            <b-input-group v-if="isEnabled(type)">
                                <b-input-group-text v-if="type.prefix">{{type.prefix}}</b-input-group-text>
                                <e-tel-input v-if="type.value === 'tel'" v-model="component.links[type.value].value" :placeholder="tr(type.placeholder)" style="flex: 1; display: flex;"/>
                                <e-input v-else :type="type.value === 'email' ? 'email' : 'text'" v-model="component.links[type.value].value" :placeholder="tr(type.placeholder)"/>
                                <b-btn variant="white" :href="component.linkHref(component.links[type.value])" :title="component.linkHref(component.links[type.value])" target="_blank" style="border: 2px solid rgba(0, 0, 0, 0.2)">
                                    <i class="fas fa-external-link"/>
                                </b-btn>
                            </b-input-group>
                        </td>
                        <td>
                            <template  v-if="isEnabled(type)">
                                <e-translation-input no-margin v-model="component.links[type.value].title"/>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </b-modal>
        <slot></slot>
    </properties-component>
</template>

<script>
import BtnResponsiveProp from "@/components/editor/properties/components/btn-responsive-prop.vue";
import PropertiesComponent from "@/components/editor/properties/components/properties-component.vue";
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import {BTableSimple} from "bootstrap-vue"
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";
import InputButtonType from "@/components/editor/properties/inputs/input-button-type.vue";
import InputColor from "@/components/editor/properties/inputs/input-color.vue";
import InputLink from "@/components/editor/properties/inputs/input-link.vue";
import SelectCmsItemField from "@/components/editor/properties/inputs/select-cms-item-field.vue";
import CmsLink from "@/helpers/cms/CmsLink.js";
import EInput from "../../../../../vue-components/components/e-input.vue";
import ETelInput from "../../../../../vue-components/components/e-tel-input.vue";
import ETranslationInput from "../../../../../vue-components/components/e-translation-input.vue";

// properties-component-social-links #hot-reload-debug
export default {
    name: `properties-component-social-links`,
    components: {
        InputColor, BTableSimple,
        BtnResponsiveProp, SidebarGroup, InputButtonType, ETranslationInput, ETelInput, InputLink, SelectCmsItemField, InputBorderRadius, EInput, PropertiesComponent},
    extends: PropertiesComponent,
    data() {
        return {
            editLinks: false,
            linkTypes: CmsLink.socialLinkTypes,
            styleExpanded: false,
            layoutExpanded: false,
            separatorExpanded: false
        }
    },
    methods: {
        toggle(type, enabled) {
            if (enabled) {
                let title = type.title;
                let value = ``;

                if (this.component.links[type.value]) {
                    title = this.component.links[type.value].title;
                    value = this.component.links[type.value].value;
                }

                this.$set(this.component.links, type.value, {
                    enabled: true,
                    type: type.value,
                    glyph: type.glyph,
                    title: title,
                    prefix: type.prefix,
                    value: value
                });
            } else {
                this.component.links[type.value].enabled = enabled;
            }
        },
        isEnabled(type) {
            return this.component.links.hasOwnProperty(type.value) && this.component.links[type.value].enabled;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
